import {
    ArrowBackIosOutlined,
    ArrowForwardIosOutlined,
  } from "@material-ui/icons";
  import { useRef, useState } from "react";
  import ListItem from "../listItem/ListItem";
  import "./list.scss";
  
  export default function List({categorias,anime}) {
    const [isMoved, setIsMoved] = useState(false);
    const [slideNumber, setSlideNumber] = useState(0);

    const listRef = useRef();
    const Animelists = anime.filter(a =>
      a.categories === categorias.id
    );
    
    const handleClick = (direction) => {
      setIsMoved(true);
      let distance = listRef.current.getBoundingClientRect().x - 50;
      if (direction === "left" && slideNumber > 0) {
        setSlideNumber(slideNumber - 1);
        listRef.current.style.transform = `translateX(${230 + distance}px)`;
      }
      if (direction === "right" && slideNumber < 5) {
        setSlideNumber(slideNumber + 1);
        listRef.current.style.transform = `translateX(${-230 + distance}px)`;
      }
    };

    const handleHorizantalScroll = (element, speed, distance, step) => {
      let scrollAmount = 0;
      const slideTimer = setInterval(() => {
        element.scrollLeft += step;
        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
          clearInterval(slideTimer);
        }
        if (element.scrollLeft === 0) {
          setIsMoved(false);
        } else {
          setIsMoved(true);
        }
      }, speed);
    };
    return (
      <div className="list">
        <span className="listTitle">{Animelists.length > 0 ? categorias.name : " "}</span>
        <div className="wrapper">
          <ArrowBackIosOutlined
            className="sliderArrow left"
            onClick={() => handleHorizantalScroll(listRef.current, 25, 600, -800)}
            style={{ display: !isMoved && "none" }}
          />
          <div className="scroll" ref={listRef}>
            <div className="container">
              {Animelists.map((item,i) => (
                <ListItem index={i} item={item} key={item.id}/>
              ))}
            </div>
          </div>
          <ArrowForwardIosOutlined
            className="sliderArrow right"
            onClick={() => handleHorizantalScroll(listRef.current, 25, 600, 800)}
          />
        </div>
      </div>
    );
  }
  