import React from 'react'
import {useState} from "react";
//var urlc="http://localhost";
//urlc="https://ws.animeflixplus.com/movil/");
/*export const url0 = "http://localhost:3000";
export const url1 = "http://localhost:3001";
export const url2 = "http://localhost:3002";
export const url3 = "http://localhost:3003";
export const url4 = "http://localhost:3004";
export const url5 = "http://localhost:3005";
export const url6 = "http://localhost:3006";
export const url7 = "http://localhost:3007";
export const url8 = "http://localhost:3008";
export const url9 = "http://localhost:3009";
export const url10 = "http://localhost:3010";
export const url11 = "http://localhost:3011";
export const url12 = "http://localhost:3012";
export const url13 = "http://localhost:3013";
export const url14 = "http://localhost:3014";
export const url15 = "http://localhost:3015";
export const url17 = "http://localhost:3017";
export const url18 = "http://localhost:3018";
export const url19 = "http://localhost:3019";
export const urlHls = "http://localhost:8080/php";
export const socketMilist = "ws://localhost:3004";
export const socketNoti = "ws://localhost:5002";*/

export const url0 = "https://ws.animeflixplus.com/movil/0";
export const url1 = "https://ws.animeflixplus.com/movil/1";
export const url2 = "https://ws.animeflixplus.com/movil/2";
export const url3 = "https://ws.animeflixplus.com/movil/3";
export const url4 = "https://ws.animeflixplus.com/movil/4";
export const url5 = "https://ws.animeflixplus.com/movil/5";
export const url6 = "https://ws.animeflixplus.com/movil/6";
export const url7 = "https://ws.animeflixplus.com/movil/7";
export const url8 = "https://ws.animeflixplus.com/movil/8";
export const url9 = "https://ws.animeflixplus.com/movil/9";
export const url10 = "https://ws.animeflixplus.com/movil/10";
export const url11 = "https://ws.animeflixplus.com/movil/11";
export const url12 = "https://ws.animeflixplus.com/movil/12";
export const url13 = "https://ws.animeflixplus.com/movil/13";
export const url14 = "https://ws.animeflixplus.com/movil/14";
export const url15 = "https://ws.animeflixplus.com/movil/15";
export const url17 = "https://ws.animeflixplus.com/movil/17";
export const url18 = "https://ws.animeflixplus.com/movil/18";
export const url19 = "https://ws.animeflixplus.com/movil/19";
export const urlHls = "https://ws.animeflixplus.com/web/4/php";
export const socketMilist = "wss://ws.animeflixplus.com/movil/4/";
export const socketNoti = "wss://ws.animeflixplus.com/push/2/";