import "./listItem.scss";
import {
  PlayArrow,
  Add,
  ThumbUpAltOutlined,
  ThumbDownOutlined,
  Replay,
  Check,
  ThumbUp,
  SettingsInputAntenna,
} from "@material-ui/icons";
import { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { url5,url2,socketMilist } from "../../api/url";
import { useAuth } from "../../api/AuthContext";
import axios from "axios";

export default function ListItem({index,item}) {
  const { user } = useAuth();
  const [mylistanime,setMylistanime] = useState(0);
  const [likeanime,setLikeanime] = useState(0);
  const [estadoanime,setEstadoanime] = useState("");
  const [likecountanime,setLikecountanime] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [anime, setAnime] = useState({});
  useEffect(() => {
    setAnime(item);
    setMylistanime(item.mylist);
    setLikeanime(item.likeanime);
    setLikecountanime(item.likecountanime);
    setEstadoanime(item.estado);

  }, [item]);

  const addlikeAnime = async (status) => {
    try {
       await axios.post(url5+"/removelikeanime",{'user': user.$id,'anime':item.id});
       await axios.post(url5+"/addlikeanime",{'user': user.$id,'anime':item.id}).then(async (response) => {
         console.log(response.data);
         setLikeanime(1);
         setLikecountanime(likecountanime+1);
      }).catch(error => {
        console.log(error)
      });
     

    } catch (errors) {
      console.log(errors)
    }
  };

  const removelikeAnime = async () => {
    try {
       await axios.post(url5+"/removelikeanime",{'user': user.$id,'anime':item.id}).then(async (response) => {
         console.log(response.data);
         setLikeanime(0);
         setLikecountanime(likecountanime-1);           
      }).catch(error => {
        console.log(error)
      });
     

    } catch (errors) {
      console.log(errors)
    }
  };

  const addMylist = async () => {
    try {
       await axios.post(url2+"/addmylist",{'user': user.$id,'anime':item.id}).then(async (response) => {
         console.log(response.data);
         setMylistanime(1);
         getSocket();          
      }).catch(error => {
        console.log(error)
      });
     

    } catch (errors) {
      console.log(errors)
    }
  };

  const removeMylist = async () => {
    try {
       await axios.post(url2+"/removemylist",{'user': user.$id,'anime':item.id}).then(async (response) => {
         console.log(response.data);
         setMylistanime(0);
         getSocket();          
      }).catch(error => {
        console.log(error)
      });
     

    } catch (errors) {
      console.log(errors)
    }
  };




  const getSocket = async () => {
    try {
      const websocket = new WebSocket(socketMilist);
      // conectado
      websocket.onopen = () => {
        console.log('connected');
        //sendMessage();
        sendMessage();
      }
      const sendMessage = () => {
        websocket.send(user.$id);
      };
      
       // al recibir un mensaje
      websocket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        // auto-crear la queryKey a partir del evento recibido
        console.log(data);
        websocket.close();
      }

     
    } catch (errors) {
      console.log(errors)
    }
  };



  
  return (
   
      <div
        className="listItem"
        style={{ left: isHovered && index * 225 - 50 + index * 2.5 }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      > 
      <Link to={`/anime/${item.id}`} className="link">
          <img
            src={item.imageUrl}
            loading="lazy"
            key={item.id}
            alt=""
          />
          <div className="name">
            <span>{item.name}</span>
          </div>
        </Link>
        {isHovered && (
          <>
          
            {/*<video src={trailer} autoPlay={true} loop muted={true}/>*/}
            <div className="itemInfo">
            <Link to={`/anime/${item.id}`} className="link">
              <div className="itemInfoTop">
                
              </div>
              <div className="desc">
                  {item.description}
              </div>
              </Link>
              <div className="icons">
                <PlayArrow className="icon" />
                {mylistanime === 0 ? <Add className="icon" onClick={addMylist}/> : <Check className="iconR" onClick={removeMylist}/>}
                {likeanime === 0 ? <ThumbUpAltOutlined className="icon" onClick={addlikeAnime}/> : <ThumbUp className="iconR" onClick={removelikeAnime}/>}
                {estadoanime === "Finalizado" ? <div className="off">Finalizado</div> : <div className="online">En Emision</div>}
              </div>
            </div>
          </>
        )}
        
      </div>
    
  );
}
