import {
    ArrowBackIosOutlined,
    ArrowForwardIosOutlined,
  } from "@material-ui/icons";
  import { useRef, useState } from "react";
  import ListItem from "../listItem/ListItem";
  import "./Mylist2.scss";
  
  export default function MyList2({anime}) {

    const listRef = useRef();

    return (
      <div className="mylist2">
        <span className="listTitle">Mi Lista</span>
        <div className="wrapper">
          <div className="scroll" ref={listRef}>
            <div className="container" >
              {anime.map((item,i) => (
                <ListItem index={i} item={item}/>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
  