import {
    ArrowBackIosOutlined,
    ArrowForwardIosOutlined,
  } from "@material-ui/icons";
  import { useRef, useState } from "react";
  import ListItem from "../listItem/ListItem";
  import "./searchList.scss";
  
  export default function SearchList({anime}) {

    const listRef = useRef();

    return (
      <div className="searchList">
        <span className="listTitle"></span>
        <div className="wrapper">
          <div className="scroll" ref={listRef}>
            <div className="container" >
              {anime.map((item,i) => (
                <ListItem index={i} item={item}/>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
  