import "./itemnoti.scss"
import { MenuItem } from '@material-ui/core';
import { Visibility } from "@material-ui/icons";
import React, { useEffect, useState,SyntheticEvent } from 'react'
import { url18,socketNoti } from "../../../api/url";
import axios from "axios";
import { useAuth } from "../../../api/AuthContext";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

export default function ItemNoti({index,item,funcOpen}) {
    const { user } = useAuth();
    const [notiA,setNotiA] = useState([]);
    const [notiC,setNotiC] = useState([]);
    const [dateN,setDateN] = useState();
    useEffect(() => {
        console.log("index:"+index);

        //const formattedDate = dayjs(item.fecha).format("D/MM/YY");
        setDateN(dayjs(item.fecha).format("D/MM/YY"));
        //console.log(formattedDate);
        //console.log("item:"+item.data);
        const json = JSON.parse(item.data);
        if(item.tipo === "notiA"){
            setNotiA(json);
        }else if (item.tipo === "notiC"){
            setNotiC(json["body"]);
        }
        
        console.log("itjjsonsonem:"+json.name);
      }, [item]);

    const viewNoti = async (idnoti) => {
        try {
            await axios.post(url18+"/viewnoti",{'user': user.$id,'idnoti':idnoti}).then(async (response) => {
                console.log(response.data);
                const websocket = new WebSocket(socketNoti);
  
                // conectado
                websocket.onopen = () => {
                console.log('connected');
                //sendMessage();
                    sendMessage();
                }
                const sendMessage = () => {
                websocket.send("cod:noti");
                };
                
                // al recibir un mensaje
                websocket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                // auto-crear la queryKey a partir del evento recibido
                //console.log(data);
                websocket.close();
                console.log('close');
                }
            }).catch(error => {
            console.log(error)
            });
        } catch (errors) {
            console.log(errors)
        }
    };

    const handleClose = () => {
        funcOpen(false);
    };

  return (
    <div className="itemNoti">
      {item.tipo === "notiA" 
      ? <MenuItem onClick={handleClose}>{/* A */}
            <div className="image">
                <Link to={`/anime/${notiA.id}/${item.id}`} className="link">
                    <img src={notiA.imageUrl} className='imageUrl'/>
                </Link>
            </div>
            <div className="name">
                <Link to={`/anime/${notiA.id}/${item.id}`} className="link">
                    Anime
                </Link>
            </div>
            <div className="info">
                <Link to={`/anime/${notiA.id}/${item.id}`} className="link">
                    {notiA.name}
                </Link>
            </div>
            <div className="status">
                {item.status === 0 ? <Visibility className="iconN" onClick={() => viewNoti(item.id)}/> : <Visibility  className="iconNR" />}
            </div>
            <div className="date">
                <Link to={`/anime/${notiA.id}/${item.id}`} className="link">
                    {dateN}
                </Link>
            </div>
        </MenuItem> 
      : <MenuItem onClick={handleClose}>{/* C */}
            <div className="image">
                <Link to={`/watch/${notiC.id}/${item.id}`} className="link">
                    <img src={notiC.imageUrl} className='imageUrl'/>
                </Link>
            </div>
            <div className="name">
                <Link to={`/watch/${notiC.id}/${item.id}`} className="link">
                    Capitulo
                </Link>    
            </div>
            <div className="info">
                <Link to={`/watch/${notiC.id}/${item.id}`} className="link">
                    {notiC.name}
                </Link>    
            </div>
           
            <div className="status">
                {item.status === 0 ? <Visibility className="iconN" onClick={() => viewNoti(item.id)}/> : <Visibility  className="iconNR" />}
            </div>
            <div className="date">
                <Link to={`/watch/${notiC.id}/${item.id}`} className="link">
                    {dateN}
                </Link>    
            </div>
        </MenuItem>
      }
      <hr className="hr"/>
    </div>
  )
}
