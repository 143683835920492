import { useEffect, useState } from "react";
import { useAuth } from "../../api/AuthContext";
import "./settings.scss"
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Switch, Typography } from "@mui/material";
import { ExpandMore } from "@material-ui/icons";
import { url11 } from "../../api/url";
import axios from "axios";

export default function Settings() {
    const [checked, setChecked] = useState([]);
    const { user } = useAuth();
    const [settings,setSettings] = useState([]);
    const [settingsa,setSettingsa] = useState([]);


  const handleToggle = (value) => () => {
    const currentIndex = settingsa.indexOf(value);
    const newChecked = [...settingsa];

    if (currentIndex === -1) {
      newChecked.push(value);
      saveSettings(value.id,"1")
    } else {
      newChecked.splice(currentIndex, 1);
      saveSettings(value.id,"0")
    }
    //console.log(newChecked);
    setSettingsa(newChecked);
  };

  useEffect(()=>{
    const getSettings= async () => {
        try {
           await axios.post(url11+"/profile",{'user': user.$id}).then((response) => {
             //console.log(response.data);
             setSettings(response.data["ajustes"]);
             setSettingsa(response.data["ajustes"].filter(a =>
                a.status === "1"
              ));
              
          }).catch(error => {
            console.log(error)
          });
         
  
        } catch (errors) {
          console.log(errors)
        }
      };
      if(settings.length === 0){
        getSettings();
      }
      
  });


  const saveSettings= async (id,status) => {
    try {
       await axios.post(url11+"/updateajustes",{'user': user.$id,'id': id,'status': status}).then((response) => {
         //console.log(response.data);          
      }).catch(error => {
        console.log(error)
      });
     

    } catch (errors) {
      console.log(errors)
    }
  }

  return (
    <div className="settings">
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            subheader={<ListSubheader>Settings</ListSubheader>}
            >

            {settings.map((item,i) => (
                <ListItem key={i}>
                    <ListItemText id="switch-list-label-wifi" primary={item.name} />
                    <Switch
                    edge="end"
                    onChange={handleToggle(item)}
                    checked={settingsa.indexOf(item) !== -1}
                    inputProps={{
                        'aria-labelledby': 'switch-list-label-wifi',
                    }}
                    />
                </ListItem>
            ))}
        </List>
    </div>
  );
}
