import { useEffect, useState } from "react";
import Featured2 from "../../components/featured/Featured2";
import List from "../../components/list/List";
import Navbar from "../../components/navbar/Navbar";
import { useAuth } from "../../api/AuthContext";
import { url3,url2,socketMilist } from "../../api/url";
import "./home.scss";
import axios from "axios";
import MyList from "../../components/myList/MyList";
import Footer from "../../components/footer/Footer";
import GoogleAd from "../../api/GoogleAd";
import Navbar2 from "../../components/navbar/Navbar2";


const Home2 = (props) => {
  const [Categoriaslits,setCategoriaslits] = useState([]);
  const [Animelits,setAnimelits] = useState([]);
  const [Myulits,setMyulits] = useState([]);
  const { user } = useAuth();
  localStorage.setItem("Pantalla","Home");
  props.funcNav(false);
  useEffect(()=>{
    const getHomeList = async () => {
      try {
         await axios.post(url3+"/home2").then((response) => {
           //console.log(url3);
           setAnimelits(response.data["anime"]);
           setCategoriaslits(response.data["categorias"]);
        }).catch(error => {
          console.log(error)
        });
       

      } catch (errors) {
        console.log(errors)
      }
    }


    if (Categoriaslits.length === 0) {
      getHomeList();
    }
  });

 

  return (
    <div className='home'>
        <Navbar2/>
        <Featured2/>
        <div className="banner">
          <GoogleAd slot="9418170229" googleAdId="ca-pub-8000262184336490"/>
        </div>
        {Categoriaslits.map((list) => (
          <List categorias={list} anime={Animelits} key={list.id}/>
        ))}
    </div>
  )
}

export default Home2

