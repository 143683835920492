import { InfoOutlined, PlayArrow, VolumeOff, VolumeUp } from "@material-ui/icons";
import "./featuredAnime.scss";
import { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";


export default function FeatureAnime({anime}) {
    const [open, setOpen] = useState(false);
    const myvideo = useRef(null)
    const [muted, setMuted] = useState(true);
    const [play, setPlay] = useState(true);


    const handleOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };

    const handleToggleMute = () => setMuted(mcurrent => !mcurrent);

    const handleTogglePlay = () => {
      setPlay(pcurrent => !pcurrent);
      if(play){
        myvideo.current.pause();
      }else{
        myvideo.current.play();
      }
    }
    //console.log(window.scrollY);
    window.onscroll = () =>{
      setPlay(window.scrollY >= 500 ? false : true);
      if(myvideo.current != null){
        if(!play){
          myvideo.current.pause();
        }else{
          myvideo.current.play();
        }
      }
      return () => (window.onscroll = null);
      
    }
    
  return (
    <div className="featuredAnime">
        {anime.headerImageUrl === "" ? <img className="img" src={anime.headerImageUrl} alt=""/> :<video className="video" ref={myvideo} src={anime.videoUrl} autoPlay={play} loop muted={muted} onClick={handleTogglePlay}/>}
        <div className="degrad" onClick={handleTogglePlay}></div>
        <div className="info">
            <img
            src={anime.titleImageUrl} 
                alt=""
            />
            <span className="desc">{anime.description}</span>
        <div className="buttons">
            <button className="more" onClick={handleOpen}>
                <InfoOutlined />
                <span>Info</span>
            </button>
            <div className="menu" onClick={handleToggleMute}>
                {muted?<VolumeOff fontSize="large"/>:<VolumeUp fontSize="large"/>} 
                
            </div>
        </div>
        </div>
        <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <Box className="modal">
                <div className="name"><h2 id="parent-modal-title">{anime.name}</h2></div>
                <br/>
                <div className="desc">
                    <p id="parent-modal-description">
                        {anime.description}.
                    </p>
                </div>
                <div className="inf">
                    <h3>Editora</h3>{anime.editoria}
                    <br/>
                    <br/>
                    <h3>Audio</h3>{anime.audio}
                    <br/>
                    <br/>
                    <h3>Subtitulos</h3>{anime.subtitulos}
                </div>
                
                
            </Box>
      </Modal>
    </div>
  )
}
