import { AccountCircle, ExpandMore } from "@material-ui/icons"
import "./profile.scss"
import { useAuth } from "../../api/AuthContext";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useRef, useState } from "react";
import { account } from "../../api/appwrite";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import { url11 } from "../../api/url";
import axios from "axios";
import sha1 from "js-sha1";


export default function Profile() {
    const { user } = useAuth();
    const [expanded, setExpanded] = useState(false);
    const nameForm = useRef(null);
    const passwordForm = useRef(null);
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [open, setOpen] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSubmitName = async (e) => {
        e.preventDefault();
        console.log(name);
        await account.updateName(name);
        updateName();
        setOpen(true);
        setName("");
      }

      const handleSubmitPassword = async (e) => {
        e.preventDefault();
        console.log(password);
        //await account.updatePassword(password);
        //updateName();
        console.log(sha1('M0ises2002'));
        setOpen(true);
        setPassword("");
      }  

    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpen(false);
    }; 
    
    const updateName= async () => {
        try {
           await axios.post(url11+"/updateprofilename",{'id': user.$id,'name':name}).then((response) => {
             console.log(response.data);
          }).catch(error => {
            console.log(error)
          });
         
  
        } catch (errors) {
          console.log(errors)
        }
      };
 
  return (
    <div className="profile">
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert
                onClose={handleClose}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
            >
                Registro Actualizado Correctamente
            </Alert>
        </Snackbar>
        <div className="avatar">
            <AccountCircle className="iconavatar"/>
        </div>
        <div className="name">
            {user.name}
        </div>
        <div className="bodyProfile">
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} disabled="true">
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Correo
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>{user.email}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>Nombre</Typography>
                <Typography sx={{ color: 'text.secondary' }}>{user.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <div className="formx">
                        <form onSubmit={handleSubmitName} ref={nameForm}>
                            <input type="text" value={name} placeholder="Nombre"  required  
                            onChange={(event) => {
                                setName(event.target.value);
                            }}/>
                            <input type="submit"  value="Actualizar" className="button" />
                        </form>
                    </div>
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
                >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Password
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>********</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <div className="formp">
                            <form onSubmit={handleSubmitPassword} ref={passwordForm}>
                                <input type="password" value={password} placeholder="Password"  required  
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                }}/>
                                <input type="submit"  value="Actualizar" className="button" />
                            </form>
                    </div>
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} disabled="true">
                <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
                >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>Eliminar Cuenta</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
                    amet egestas eros, vitae egestas augue. Duis vel est augue.
                </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    </div>
  )
}
