import "./anime.scss"
import FeaturedAnime from "../../components/featuredAnime/FeaturedAnime";
import List from "../../components/list/List";
import Navbar from "../../components/navbar/Navbar";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { url7, url13,url6,url5,url2,url18,socketNoti } from "../../api/url";
import { useAuth } from "../../api/AuthContext";
import axios from "axios";
import { Form } from "react-bootstrap";
import ListCapitulos from "../../components/listCapitulos/ListCapitulos";
import { Add, Check, Settings, ThumbDown, ThumbDownOutlined, ThumbUp, ThumbUpAltOutlined, Visibility } from "@material-ui/icons";
import Footer from "../../components/footer/Footer";
import { Skeleton } from "@mui/material";


export default function Anime() {
    const { user } = useAuth();
    const [Animelits,setAnimelits] = useState([]);
    const [Temporadaslits,setTemporadaslits] = useState([]);
    const [Capituloslits,setCapituloslits] = useState([]);
    const [Audiolist,setAudiolist] = useState([]);

    const [Audio,setAudio] = useState([]);
    const [Temporada,setTemporada] = useState([]);

    const [Capitulosxlits,setCapitulosxlits] = useState([]);
    const [Capitulosalits,setCapitulosalits] = useState([]);

    const [viewani,setViewani] = useState(null);
    //const location = useLocation();
    //console.log(location);


    const [mylistanime,setMylistanime] = useState(0);
    const [mylistcountanime,setMyliscountanime] = useState(0);
    const [likeanime,setLikeanime] = useState(0);
    const [likecountanime,setLikecountanime] = useState(0);
    const [viewanime,setViewanime] = useState(0);
    const [viewcountanime,setViewcountanime] = useState(0);

    const [cf,setCf]  = useState(true);
    
    const { id,noti } = useParams();
    //console.log(id)
    const Tempo = localStorage.getItem("Temporada");
    const Aud = localStorage.getItem("Audio");
    const Pantalla = localStorage.getItem("Pantalla");
    useEffect(async ()=>{
       
        const getAniList = async () => {
          try {
             await axios.post(url13+"/anime",{'user': user.$id,'idanime':id}).then((response) => {
               //console.log(response.data);
               setAnimelits(response.data["anime"]);
            }).catch(error => {
              console.log(error)
            });
           
    
          } catch (errors) {
            console.log(errors)
          }
        };
        
        const getCapList = async () => {
          try {
             await axios.post(url7+"/capitulos",{'user': user.$id,'anime':id}).then(async (response) => {
               //console.log(response.data);
                setTemporadaslits(response.data["temporadas"]);
                setCapituloslits(response.data["capitulos"]);
                setAudiolist(response.data["audio"]);
            }).catch(error => {
              console.log(error)
            });
           
    
          } catch (errors) {
            console.log(errors)
          }
        };

        
        
        if (Animelits.length === 0) {
            await getAniList();
        }
        if (Temporadaslits.length === 0) {
            await getCapList();
        }

        
        
        //console.log("audio:"+Audiolist);
      
        const setCapByTempAudi = async () =>{
          if(Pantalla==="Home"){
            setCapitulosalits(Capituloslits.filter(c => {
              return(
                c.temporada === Temporadaslits[0].id &&
                c.audio === Audiolist[0].id 
              );
            }));
            setTemporada(Temporadaslits[0].id);
            setAudio(Audiolist[0].id);
            localStorage.setItem("Temporada",Temporadaslits[0].id);
          }else{
            setCapitulosalits(Capituloslits.filter(c => {
              return(
                c.temporada === parseInt(Tempo) &&
                c.audio === Audiolist[0].id 
              );
              
            }));
            setTemporada(parseInt(Tempo));
            setAudio(Audiolist[0].id)
          }
          //localStorage.setItem("Temporada",Temporadaslits[0].id);
          //localStorage.setItem("Audio",Audiolist[0].id);
           //setTemporada(Temporadaslits[0].id);
           //setAudio(Audiolist[0].id)
        }


        const setCapByTempAudi2 = async () =>{
           setCapitulosalits(Capitulosxlits.filter(c => c.audio === Audiolist[0].id));
           setAudio(Audiolist[0].id)
        }


        if (Temporadaslits.length > 0 && Capituloslits.length > 0 && Audiolist.length > 0 && cf) {
          if(Capitulosxlits.length === 0){
            setCapByTempAudi();
            setCf(false);
          }
        }


        
        console.log("Tempo:"+Tempo);
        console.log("Pantalla:"+Pantalla);
  
      });

      /*useEffect(() =>{
        console.log("Tempo:"+Tempo);
        console.log("Audio:"+Aud);
        setCapitulosalits(Capituloslits.filter(c => {
          return(
            c.temporada === Tempo &&
            c.audio === Aud
          );
        }));
      },[Tempo,Aud]);*/

      useEffect(() => {
        if(Animelits.name != null){
          setMylistanime(Animelits.mylist);
          setLikeanime(Animelits.likeanime);
          setLikecountanime(Animelits.likecountanime);
          setViewanime(Animelits.viewanime);
          setViewcountanime(Animelits.viewcountanime);


          if(viewani === null){
            setViewani(Animelits.viewanime);
          }
          if(viewani === 0){
            setViewani(1)
          }
        }
      },[Animelits]);

      useEffect(() => {
        const setView = async () => {
          try {
             await axios.post(url6+"/viewanime",{'user': user.$id,'anime':id}).then(async (response) => {
               console.log(response.data);
            }).catch(error => {
              console.log(error)
            });
           
    
          } catch (errors) {
            console.log(errors)
          }
        };

        //console.log("userEffect2:"+viewani);
        if(viewani === 0){
          setView();
          //console.log("Viewani:"+viewani);
          //console.log("View");
        }

      },[viewani]);



      const handleSelectTemporadaChange = (e) => {
          setTemporada(e.target.value);
          localStorage.setItem("Temporada",e.target.value);
          //setCapitulosalits(Capituloslits.filter(c => c.temporada === parseInt(e.target.value)));
          let filtercap = Capituloslits;
          filtercap = filtercap.filter(c => {
            return(
              c.temporada === parseInt(e.target.value) &&
              c.audio === parseInt(Audio) 
            );
          });
          //setCapitulosalits(Capitulosxlits.filter(c => c.audio === Audio));
          if(filtercap.length > 0){
            setCapitulosalits(filtercap); 
          }else{
            setCapitulosalits([]);
          }
          console.log("select:"+filtercap.length)
      };
      const handleSelectAudioChange = (e) => {
        setAudio(e.target.value);
        localStorage.setItem("Audio",e.target.value);
        //setCapitulosalits(Capituloslits.filter(c => c.audio === parseInt(e.target.value)));
        let filtercap = Capituloslits;
        filtercap = filtercap.filter(c => {
          return(
            c.audio === parseInt(e.target.value) &&
            c.temporada === parseInt(Temporada) 
          );
        });

        if(filtercap.length > 0){
          setCapitulosalits(filtercap); 
        }else{
          setCapitulosalits([]);
        }
        console.log("select:"+filtercap.length)
    };
    
    const addlikeAnime = async (status) => {
      try {
         await axios.post(url5+"/removelikeanime",{'user': user.$id,'anime':id});
         await axios.post(url5+"/addlikeanime",{'user': user.$id,'anime':id}).then(async (response) => {
           console.log(response.data);
           setLikeanime(1);
           setLikecountanime(likecountanime+1);
        }).catch(error => {
          console.log(error)
        });
       
  
      } catch (errors) {
        console.log(errors)
      }
    };

    const removelikeAnime = async () => {
      try {
         await axios.post(url5+"/removelikeanime",{'user': user.$id,'anime':id}).then(async (response) => {
           console.log(response.data);
           setLikeanime(0);
           setLikecountanime(likecountanime-1);           
        }).catch(error => {
          console.log(error)
        });
       
  
      } catch (errors) {
        console.log(errors)
      }
    };

    const addMylist = async () => {
      try {
         await axios.post(url2+"/addmylist",{'user': user.$id,'anime':id}).then(async (response) => {
           console.log(response.data);
           setMylistanime(1);          
        }).catch(error => {
          console.log(error)
        });
       
  
      } catch (errors) {
        console.log(errors)
      }
    };

    const removeMylist = async () => {
      try {
         await axios.post(url2+"/removemylist",{'user': user.$id,'anime':id}).then(async (response) => {
           console.log(response.data);
           setMylistanime(0);          
        }).catch(error => {
          console.log(error)
        });
       
  
      } catch (errors) {
        console.log(errors)
      }
    };

    useEffect(()=>{
      const viewNoti = async (idnoti) => {
        try {
            await axios.post(url18+"/viewnoti",{'user': user.$id,'idnoti':noti}).then(async (response) => {
                console.log(response.data);
                const websocket = new WebSocket(socketNoti);
  
                // conectado
                websocket.onopen = () => {
                console.log('connected');
                //sendMessage();
                    sendMessage();
                }
                const sendMessage = () => {
                websocket.send("cod:noti");
                };
                
                // al recibir un mensaje
                websocket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                // auto-crear la queryKey a partir del evento recibido
                //console.log(data);
                websocket.close();
                console.log('close');
                }
            }).catch(error => {
            console.log(error)
            });
        } catch (errors) {
            console.log(errors)
        }
      };
  
      if(noti != null){
        viewNoti();
      }
  
    },[noti]);
    
  return (
    <div className="anime">
      {/*<Navbar/>*/}
      <FeaturedAnime anime={Animelits}/>
      <div className="settings">
        <Settings/>
        <div className="options">
            <span>Opciones</span>
            <span>Salir</span>
        </div>
      </div>
      <div className="iconCap">
          {mylistanime === 0 ? <Add className="icon" onClick={addMylist}/> : <Check className="iconR" onClick={removeMylist}/>}
          <div className="spacin"></div>
          {likeanime === 0 ? <ThumbUpAltOutlined className="icon" onClick={addlikeAnime}/> : <ThumbUp className="iconR" onClick={removelikeAnime}/>}
          <div className="spacin"></div>
          {viewanime === 0 ? <Visibility className="icon" /> : <Visibility  className="iconR" />}
        </div>
        <div className="count">
          Lista
          <div className="spacin"></div>
          {likecountanime}
          <div className="spacin"></div>
          {viewcountanime}
        </div>
      <div className="temporada">
        <Form.Select id="temporada" value={Temporada} size="lg" onChange={handleSelectTemporadaChange}>
          {Temporadaslits.map((list) => (
            <option value={list.id}>{list.name}</option>
          ))}
        </Form.Select>
      </div>
      <div className="audio">
        <Form.Select id="audio" value={Audio} size="lg" onChange={handleSelectAudioChange}>
          {Audiolist.map((list) => (
            <option value={list.id}>{list.name}</option>
          ))}
        </Form.Select>
      </div>
      <div className="infoNo">
          {Capitulosalits.length === 0 ? 
            (Array.from(new Array(4))).map((item, index) => (
              <Skeleton variant="rectangular" width={330} height={270} className="skeleton"/>
            ))
          :''}
      </div>
      {Capitulosalits.map((list) => (
          <ListCapitulos capitulos={list} anime={Animelits} />
      ))}
      
    </div>
  )
}
