import {
    ArrowBackIosOutlined,
    ArrowForwardIosOutlined,
  } from "@material-ui/icons";
  import { useRef, useState } from "react";
  import ListItem from "../listItem/ListItem";
  import "./newclist.scss";
import NewListItem from "../newlistitem/NewListItem";
  
  export default function NewCList({capitulos}) {
    const [isMoved, setIsMoved] = useState(false);
    const [slideNumber, setSlideNumber] = useState(0);

    const listRef = useRef();

    const handleHorizantalScroll = (element, speed, distance, step) => {
      let scrollAmount = 0;
      const slideTimer = setInterval(() => {
        element.scrollLeft += step;
        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
          clearInterval(slideTimer);
        }
        if (element.scrollLeft === 0) {
          setIsMoved(false);
        } else {
          setIsMoved(true);
        }
      }, speed);
    };
    return (
      <div className="newlist">
        <span className="listTitle">Capitulos de Hoy</span>
        <div className="divid"></div>
        <div className="wrapper">
            <div className="container">
              {capitulos.map((item,i) => (
                <NewListItem index={i} item={item} key={item.id}/>
              ))}
            </div>
        </div>
      </div>
    );
  }
  