import { useState } from "react";
import { AuthProvider  } from "./api/AuthContext";
import PrivateRoutes from "./api/PrivateRoutes";
import "./app.scss"
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import Anime from "./pages/anime/Anime";
import Home from "./pages/home/Home";
import Home2 from "./pages/home/Home2";
import Login from "./pages/login/Login";
import Logout from "./pages/logout/logout";
import Milista from "./pages/milista/Milista";
import Register from "./pages/register/Register";
import Watch from "./pages/watch/Watch";
import {
  BrowserRouter as Router,  Routes,  Route,  Navigate,} from "react-router-dom";
import Search from "./pages/search/Search";
import Profile from "./pages/profile/Profile";
import Settings from "./pages/settings/Settings";

const App = () => {
  const user = true;
  const [showNav, setShowNav] = useState(true);
  return (
    <Router>
       
      <AuthProvider>
      {showNav &&
        <nav>
          <Navbar />
        </nav>
      }
        <Routes>
          <Route exact path="/login" element={<Login funcNav={setShowNav}/>} />
          <Route exact path="/register" element={<Register funcNav={setShowNav}/>}/>
          <Route element={<PrivateRoutes/>}>
            <Route exact path="/" element={<Home funcNav={setShowNav}/>}/>
            <Route exact path="/logout" element={<Logout/>}/>
            <Route exact path="/anime/:id/:noti?" element={user ? <Anime/>: <Navigate replace to="/login"/>}/>
            <Route exact path="/watch/:id/:noti?" element={user ? <Watch/>: <Navigate replace to="/login"/>}/>
            <Route exact path="/milista" element={user ? <Milista/>: <Navigate replace to="/login"/>}/>
            <Route exact path="/search/:id" element={user ? <Search/>: <Navigate replace to="/login"/>}/>
            <Route exact path="/profile" element={user ? <Profile/>: <Navigate replace to="/login"/>}/>
            <Route exact path="/settings" element={user ? <Settings/>: <Navigate replace to="/login"/>}/>
          </Route>
          <Route path="/*" element={<Home/>} />
        </Routes>
      </AuthProvider>
      <Footer/>
    </Router>
  );
};

export default App;