import {
    ArrowBackIosOutlined,
    ArrowForwardIosOutlined,
  } from "@material-ui/icons";
  import { useRef, useState } from "react";
  import "./listCapitulos.scss";
import ListItemCapitulos from "../listItemCapitulos/ListItemCapitulos";
  
  export default function ListCapitulos({capitulos,anime}) {
    const [isMoved, setIsMoved] = useState(false);
    const [slideNumber, setSlideNumber] = useState(0);

    const listRef = useRef();
    /*const Animelists = anime.filter(a =>
      a.categories === categorias.id
    );*/
    

    return (
      <div className="listCapitulos">
        <div className="wrapper">
          <div className="container" ref={listRef}>
              <ListItemCapitulos index={1} item={capitulos} ani={anime}/>
          </div> 
        </div>
      </div>
    );
  }
  