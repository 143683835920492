import { useEffect, useState } from "react";
import "./search.scss"
import { useAuth } from "../../api/AuthContext";
import { url19 } from "../../api/url";
import { useParams } from "react-router-dom";
import axios from "axios";
import MyList2 from "../../components/myList/MyList2";
import SearchList from "../../components/search/SearchList";

export default function Search() {
    const { id } = useParams();
    const { user } = useAuth();
    const [Animelits,setAnimelits] = useState([]);
    useEffect(async ()=>{
        //console.log(id);
        const getAniList = async () => {
            try {
               await axios.post(url19+"/search",{'user': user.$id,'anime':id}).then((response) => {
                 console.log(response.data["anime"]);
                 setAnimelits(response.data["anime"]);
              }).catch(error => {
                console.log(error);
                setAnimelits([]);
              });
             
      
            } catch (errors) {
              console.log(errors)
            }
          };

            await getAniList();
      },[id]);
  return (
    <div className="search">
      <div className="infoNo">
          {Animelits.length === 0 ? 'No videos':''}
      </div>
        {Animelits.length === 0 ? '': <SearchList anime={Animelits}/>}
    </div>
  )
}
