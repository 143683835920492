import "./milista.scss"
import FeaturedAnime from "../../components/featuredAnime/FeaturedAnime";
import List from "../../components/list/List";
import Navbar from "../../components/navbar/Navbar";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { url7, url13,url6,url5,url2,socketMilist } from "../../api/url";
import { useAuth } from "../../api/AuthContext";
import axios from "axios";
import { Form } from "react-bootstrap";
import ListCapitulos from "../../components/listCapitulos/ListCapitulos";
import { Add, Check, Settings, ThumbDown, ThumbDownOutlined, ThumbUp, ThumbUpAltOutlined, Visibility } from "@material-ui/icons";
import Footer from "../../components/footer/Footer";
import MyList from "../../components/myList/MyList";
import MyList2 from "../../components/myList/MyList2";
import socketIO from 'socket.io-client';
//const socket = socketIO.connect('ws://localhost:3004');

 

export default function Milista() {
    localStorage.setItem("Pantalla","Milista");
    const { user } = useAuth();
    const [Myulits,setMyulits] = useState([]);
    useEffect(async ()=>{
        const getMyAniList = async () => {
            try {
               await axios.post(url2+"/mylist",{'user': user.$id}).then((response) => {
                 //console.log(url3);
                 //setMyulits(response.data["anime"]);
              }).catch(error => {
                console.log(error)
              });
             
      
            } catch (errors) {
              console.log(errors)
            }
          };

          if (Myulits.length === 0) {
            getMyAniList();
          }
    });


    useEffect(async ()=>{
      const websocket = new WebSocket(socketMilist);

      // conectado
      websocket.onopen = () => {
        console.log('connected');
        //sendMessage();
        if (Myulits.length === 0) {
          sendMessage();
        }
      }
      const sendMessage = () => {
        websocket.send(user.$id);
      };
      
       // al recibir un mensaje
      websocket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        // auto-crear la queryKey a partir del evento recibido
        console.log(data);
        if(data["user"]=== user.$id){
          setMyulits(data["anime"]);
        }
        websocket.close();
        console.log('close');
      }
      // desconexión
      return () => {
        websocket.close();
      } 

      
    });
    
  return (
    <div className="milista">
      {/*<Navbar/>*/}
      <div className="infoNo">
          {Myulits.length === 0 ? 'No videos':''}
      </div>
        <MyList2 anime={Myulits}/>
    </div>
  )
}
