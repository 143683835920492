import { Notifications, NotificationsActive } from "@material-ui/icons"
import "./notification.scss"
import { useEffect, useRef, useState } from "react";
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Skeleton } from "@mui/material";
import { socketNoti } from "../../../api/url";
import ItemNoti from "./ItemNoti";

export default function Notification() {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [notiList,setNotiList] = useState([]);
    const [notiStatus,setNotiStatus] = useState([]);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
      };

    useEffect(async ()=>{
        const websocket = new WebSocket(socketNoti);
  
        // conectado
        websocket.onopen = () => {
          //console.log('connected');
          //sendMessage();
          if (notiList.length === 0) {
            sendMessage();
          }
        }
        const sendMessage = () => {
          websocket.send("cod:noti");
        };
        
         // al recibir un mensaje
        websocket.onmessage = (event) => {
          const data = JSON.parse(event.data);
          // auto-crear la queryKey a partir del evento recibido
          //console.log(data);
          if(data != ""){
            setNotiStatus(data["notificacion"].filter(a =>
              a.status === 0
            ));
            setNotiList(data["notificacion"]);
          }
          websocket.close();
          //console.log('close');
        }
        // desconexión
        return () => {
          websocket.close();
        } 
  
        
      });

      const handleClose = () => {
        setOpen(false);
      };

      /*useEffect(()=>{

        /*setNotiStatus(notiList.filter(a =>
          a.status === 0
        ));

        console.log("notiStatus:"+notiStatus.length);
      },[notiList]);*/
    
  return (
    <div className="notification">
      {notiStatus.length > 0 ? <NotificationsActive className="iconR" onClick={handleToggle}/> : <Notifications className="icon" onClick={handleToggle}/>}
      <div className="opciones">
        <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            >
            {({ TransitionProps, placement }) => (
                <Grow
                {...TransitionProps}
                style={{
                    transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
                >
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                    >
                        {notiList.length === 0 ? 
                          (Array.from(new Array(4))).map((item, index) => (
                            <><Skeleton variant="rectangular" animation="wave" width={70} height={90} className="skeletonrect"/>
                            <Skeleton animation="wave" width={410} height={100} /></>
                          ))
                        :''}
                        {notiList.map((item,i) => (
                            <ItemNoti index={i} item={item} funcOpen={setOpen}/>
                        ))}
                    </MenuList>
                    </ClickAwayListener>
                </Paper>
                </Grow>
            )}
            </Popper>
      </div>
      
    </div>
  )
}
