import React, { useState, useRef, useEffect } from 'react'
import style from './SearchBar.module.css'
import { Search } from '@material-ui/icons'
import { useNavigate } from 'react-router-dom'


export const SearchBar = () => {
  const [expand, setExpand] = useState(false)
  const [searchInputText,setSearchInputText] = useState("");
  const [searchPantalla,setSearchPantalla] = useState("");
  const searchInput = useRef(null);
  const navigate = useNavigate();
  const Pantalla = localStorage.getItem("Pantalla");
  const handlerExpand = () => {
    setExpand(true)
    searchInput.current.focus()
  }
  const handlerCollapse = () => {
    setExpand(false)
  }

  const handlerChange = (e) =>{
    console.log(e.target.value);
    setSearchInputText(e.target.value);
     
  }

  useEffect(() => {
    //console.log(Pantalla);
    setSearchPantalla(Pantalla);
    if(searchInputText.length > 0){
      navigate(`/search/${searchInputText}`);
    }else{
      if(searchPantalla==="Home"){
        navigate("/");
      }else if(searchPantalla==="Milista"){
        navigate("/milista");
      }
      
    }
    
},[searchInputText]);

  return (
        <div className={style.searchBar}>
            <div className={expand ? style.search_field_expand : style.search_field}>
                <Search
                    onClick={handlerExpand}
                    className={style.icon}/>
                <input
                    value={searchInputText}
                    ref={searchInput}
                    onBlur={handlerCollapse}
                    onChange={e => handlerChange(e)}
                    type="text"
                    placeholder="Animes"
                    name="search-input"
                    className={style.input_text} />
            </div>
        </div>
  )
}
