import { useEffect, useState } from "react";
import Featured from "../../components/featured/Featured";
import List from "../../components/list/List";
import Navbar from "../../components/navbar/Navbar";
import { useAuth } from "../../api/AuthContext";
import { url3,url2,socketMilist,url17 } from "../../api/url";
import "./home.scss";
import axios from "axios";
import MyList from "../../components/myList/MyList";
import Footer from "../../components/footer/Footer";
import GoogleAd from "../../api/GoogleAd";
import NewCList from "../../components/newCapitulos/NewCList";


const Home = (props) => {
  const [Categoriaslits,setCategoriaslits] = useState([]);
  const [Animelits,setAnimelits] = useState([]);
  const [Myulits,setMyulits] = useState([]);
  const [newCaplits,setNewCaplits] = useState([]);
  const [newCapStatus,setNewCapStatus] = useState(false);
  const { user } = useAuth();
  localStorage.setItem("Pantalla","Home");
  props.funcNav(true);
  useEffect(()=>{
    const getHomeList = async () => {
      try {
         await axios.post(url3+"/home",{'user': user.$id}).then((response) => {
           //console.log(url3);
           setAnimelits(response.data["anime"]);
           setCategoriaslits(response.data["categorias"]);
        }).catch(error => {
          console.log(error)
        });
       

      } catch (errors) {
        console.log(errors)
      }
    }
    const getMyAniList = async () => {
      try {
         await axios.post(url2+"/mylist",{'user': user.$id}).then((response) => {
           //console.log(url3);
           //setMyulits(response.data["anime"]);
        }).catch(error => {
          console.log(error)
        });
       

      } catch (errors) {
        console.log(errors)
      }
    };


    if (Categoriaslits.length === 0) {
      getHomeList();
    }
    if (Myulits.length === 0) {
      getMyAniList();
    }
  });

  useEffect(async ()=>{
    const getNewCList = async () => {
      try {
         await axios.get(url17+"/addnewcap").then((response) => {
           //console.log(url3);
           setNewCaplits(response.data["newcap"]);
           setNewCapStatus(true);
        }).catch(error => {
          console.log(error)
        });
       

      } catch (errors) {
        console.log(errors)
      }
    }

    if (!newCapStatus) {
      getNewCList();
    }
    
  });

  useEffect(async ()=>{
    const websocket = new WebSocket(socketMilist);
    try {
      

      // conectado
      websocket.onopen = () => {
        //console.log('connected');
        //sendMessage();
        if (Myulits.length === 0) {
          sendMessage();
        }
      }
      const sendMessage = () => {
        websocket.send(user.$id);
      };
      
      // al recibir un mensaje
      websocket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        // auto-crear la queryKey a partir del evento recibido
        //console.log(data);
        if(data["user"]=== user.$id){
          setMyulits(data["anime"]);
        }
        
        websocket.close();
        //console.log('close');
      }
      // desconexión
      return () => {
        websocket.close();
        console.log('return close');
      } 
    } catch (error) {
      websocket.close();
    }
    
  });

 

  return (
    <div className='home'>
        {/*<Navbar/>*/}
        <Featured/>
        <div className="banner">
          <GoogleAd slot="9418170229" googleAdId="ca-pub-8000262184336490"/>
        </div>
        <MyList anime={Myulits}/>
        {newCaplits.length > 0 ? <NewCList capitulos={newCaplits} />:""}
        {Categoriaslits.map((list) => (
          <List categorias={list} anime={Animelits} key={list.id}/>
        ))}
    </div>
  )
}

export default Home

