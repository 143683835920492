import "./listItemCapitulos.scss";
import {
  PlayArrow,
  Add,
  ThumbUpAltOutlined,
  ThumbDownOutlined,
  Replay,
  ThumbUp,
  ThumbDown,
  Visibility,
} from "@material-ui/icons";
import { useEffect,useState } from "react";
import { Link } from "react-router-dom";

export default function ListItemCapitulos({index,item,ani}) {
  const [isHovered, setIsHovered] = useState(false);
  const [anime, setAnime] = useState({});
  useEffect(() => {
    setAnime(item);
  }, [item]);
  
  return (
    <Link to={`/watch/${item.id}`} className="link">
      <div
        className="listItemCapitulos"
        style={{ left: isHovered && index * 225 - 50 + index * 2.5 }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {!isHovered && (
          <>
            <img
              src={item.imageUrl}
              alt=""
            />
            <div className="view">
              {item.viewcapitulo > 0 ? <Replay fontSize="large" className="viewicon"/> : <PlayArrow fontSize="large" className="viewicon" />}
            </div>
            <div className="namecap">
                {item.name}
            </div>
          </>
        )}
        {isHovered && (
          <>
            {/*<video src={trailer} autoPlay={true} loop muted={true}/>*/}
            <div className="itemInfo">
              <div className="name">
                  {ani.name}
              </div>
              <div className="icons">
                {item.viewcapitulo > 0 ? <Replay className="iconR"/> : <PlayArrow  className="icon" />}
                {item.likecapitulo === 0 ? <ThumbUpAltOutlined className="icon" /> : <ThumbUp className="iconR" />}
                {item.dislikecapitulo === 0 ? <ThumbDownOutlined className="icon" /> : <ThumbDown  className="iconR" />}
                {item.viewanime === 0 ? <Visibility className="icon" /> : <Visibility  className="iconR" />}
              </div>
              <div className="itemInfoTop">
                {item.name}
              </div>
              <div className="desc">
                  {item.description}
              </div>
              <div className="genre">
                  {item.audio === 1 ? 'Latino':'Subtitulado'}
              </div>
            </div>
          </>
        )}
      </div>
    </Link>
  );
}
