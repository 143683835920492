import { useEffect } from "react";
import { useAuth } from "../../api/AuthContext";

const Logout = () => {
    const { logoutUser } = useAuth();

    useEffect(() => {
       logoutUser();
      });
  return (
    <div>
        
    </div>
  )
}

export default Logout