import "./newlistItem.scss";
import {
  PlayArrow,
  Add,
  ThumbUpAltOutlined,
  ThumbDownOutlined,
  Replay,
  Check,
  ThumbUp,
  SettingsInputAntenna,
} from "@material-ui/icons";
import { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { url5,url2,socketMilist } from "../../api/url";
import { useAuth } from "../../api/AuthContext";
import axios from "axios";

export default function NewListItem({index,item}) {
  const { user } = useAuth();
  const [mylistanime,setMylistanime] = useState(0);
  const [likeanime,setLikeanime] = useState(0);
  const [estadoanime,setEstadoanime] = useState("");
  const [likecountanime,setLikecountanime] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [anime, setAnime] = useState({});
  useEffect(() => {
    setAnime(item);
    setMylistanime(item.mylist);
    setLikeanime(item.likeanime);
    setLikecountanime(item.likecountanime);
    setEstadoanime(item.estado);

  }, [item]);

  const addlikeAnime = async (status) => {
    try {
       await axios.post(url5+"/removelikeanime",{'user': user.$id,'anime':item.id});
       await axios.post(url5+"/addlikeanime",{'user': user.$id,'anime':item.id}).then(async (response) => {
         console.log(response.data);
         setLikeanime(1);
         setLikecountanime(likecountanime+1);
      }).catch(error => {
        console.log(error)
      });
     

    } catch (errors) {
      console.log(errors)
    }
  };

  const removelikeAnime = async () => {
    try {
       await axios.post(url5+"/removelikeanime",{'user': user.$id,'anime':item.id}).then(async (response) => {
         console.log(response.data);
         setLikeanime(0);
         setLikecountanime(likecountanime-1);           
      }).catch(error => {
        console.log(error)
      });
     

    } catch (errors) {
      console.log(errors)
    }
  };

  const addMylist = async () => {
    try {
       await axios.post(url2+"/addmylist",{'user': user.$id,'anime':item.id}).then(async (response) => {
         console.log(response.data);
         setMylistanime(1);       
      }).catch(error => {
        console.log(error)
      });
     

    } catch (errors) {
      console.log(errors)
    }
  };

  const removeMylist = async () => {
    try {
       await axios.post(url2+"/removemylist",{'user': user.$id,'anime':item.id}).then(async (response) => {
         console.log(response.data);
         setMylistanime(0);        
      }).catch(error => {
        console.log(error)
      });
     

    } catch (errors) {
      console.log(errors)
    }
  };

  return (
   
      <div
        className="newlistItem"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      > 
      <Link to={`/anime/${item.idanime}`} className="link">
          <div className="imgA">
            <img className="ani"
                src={item.imageUrlanime}
                loading="lazy"
                key={item.id}
                alt=""
              />
          </div>
          <div className="name">
            <span>{item.anime}</span>
          </div>
          <div className="desc"> 
              {item.name}
          </div>
        </Link>
        {isHovered && (
          <>
          
            {/*<video src={trailer} autoPlay={true} loop muted={true}/>*/}
            <div className="itemInfo">
            <Link to={`/watch/${item.id}`} className="link">
              <div className="imgC">
                <img className="cap"
                      src={item.imageUrl}
                      loading="lazy"
                      key={item.id}
                      alt=""
                    />
              </div>
              <div className="icons"> 
                <PlayArrow className="icon"/>
              </div>
              </Link>
            </div>
          </>
        )}
        
      </div>
    
  );
}
